<template>
  <div>
    <h1>当前版本v1.1.1</h1>
    <h2>
      历史更新记录
    </h2>
    <div>
      <div>
        <h3>
          v1.1.1
        </h3>
        <p>2022年4月27日</p>
        <p>1. 帖子高亮修复</p>
        <p>2. 方案输入框提示语修复</p>
      </div>
      <div>
        <h3>
          v1.1.0
        </h3>
        <p>2022年4月26日</p>
        <p>全新ui</p>
      </div>
      <div>
        <h3>
          v1.0.0
        </h3>
        <p>2022年1月28日</p>
        <p>初始版本上线</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
